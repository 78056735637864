import React, { useEffect } from "react";
import ButtonNextStep from "./ButtonNextStep";
import ProductInfo from "./productInfo/ProductInfo";
import "./BodyMeasures.css";

const BodyMeasures = ({
  lang,
  gender,
  ageInput,
  heightInput,
  weightInput,
  age,
  height,
  weight,
  onChange,
  onRadioButtonChange,
  onNextStep,
  errorAge,
  errorHeight,
  errorWeight,
  errorMsg,
  productInfo,
  shopShowGender,
}) => {
  useEffect(() => {
    if (shopShowGender === "selectable" && gender !== "") {
      let selectedGenderButtons = document.querySelector(
        "input[name='gender'][value=" + gender + "]"
      );
      selectedGenderButtons.checked = true;
    }
  });

  let txtMain,
    txtSecondary,
    txtMale,
    txtFemale,
    txtAge,
    txtHeight,
    txtWeight,
    txtInfo,
    dataUnitCM,
    dataUnitKG;
  switch (lang) {
    case "en":
      txtMain = "Find your size";
      txtSecondary = "Just answer a few simple questions.";
      txtMale = "Male";
      txtFemale = "Female";
      txtAge = "How old are you?";
      txtHeight = "Height";
      txtWeight = "Weight";
      txtInfo =
        "This information is important for a proper recommendation. We advise you to enter accurate data.";
      dataUnitCM = "cm";
      dataUnitKG = "kg";
      break;
    default:
      txtMain = "Намери своя размер";
      txtSecondary = "Отговори на няколко бързи въпроси и разбери.";
      txtMale = "Мъж";
      txtFemale = "Жена";
      txtAge = "На колко години си?";
      txtHeight = "Височина";
      txtWeight = "Тегло";
      txtInfo =
        "Тази информация е важна за правилната препоръка. Съветваме те да въведеш точни данни.";
      dataUnitCM = "см";
      dataUnitKG = "кг";
  }

  return (
    <div className="screen-body-measures">
      <p className="error-msg">{errorMsg}</p>
      <div className="top-text">
        <h2 className="mrg-0">{txtMain}</h2>
        <p className="mrg-0">{txtSecondary}</p>
      </div>
      <ProductInfo productInfo={productInfo} />
      {shopShowGender === "selectable" ? (
        <div>
          <label className="inline-radio-btn" htmlFor="gender_woman">
            {txtFemale}
            <input
              id="gender_woman"
              type="radio"
              name="gender"
              value="female"
              onChange={(e) => {
                onRadioButtonChange(e);
              }}
            />
            <span className="checkmark"></span>
          </label>
          <label className="inline-radio-btn" htmlFor="gender_man">
            {txtMale}
            <input
              id="gender_man"
              type="radio"
              name="gender"
              value="male"
              onChange={(e) => {
                onRadioButtonChange(e);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ) : (
        <></>
      )}
      <div className="container-body-measures">
        <label htmlFor="age">{txtAge}</label>
        <input type="number" value={age} name={ageInput} onChange={onChange} />
        <p className="error-msg">{errorAge}</p>
        <label htmlFor="height">{txtHeight}</label>
        <label className="units-label" data-units={dataUnitCM}>
          <input
            type="number"
            value={height}
            name={heightInput}
            onChange={onChange}
            min="140"
            max="220"
          />
        </label>
        <p className="error-msg">{errorHeight}</p>
        <label htmlFor="weight">{txtWeight}</label>
        <label className="units-label" data-units={dataUnitKG}>
          <input
            type="number"
            value={weight}
            name={weightInput}
            onChange={onChange}
            min="35"
            max="150"
          />
        </label>
        <p className="error-msg">{errorWeight}</p>
      </div>
      <div className="information">
        <img src="./info_icon.png" alt="info icon" />
        <p>{txtInfo}</p>
      </div>

      <ButtonNextStep validate={onNextStep} lang={lang} />
    </div>
  );
};

export default BodyMeasures;
