import React, { useEffect } from "react";
import "./bodyPartShape.css";
import { Link } from "react-router-dom";
import ButtonNextStep from "../ButtonNextStep";

const BodyPartShape = ({
  lang,
  gender,
  text,
  name,
  value,
  onRadioButtonChange,
  onNextStep,
  errorMsg,
  prevWindow,
  txtBtnPreviousStep,
  checkPrevData,
}) => {
  useEffect(() => {
    checkPrevData();
    // Remove any "checked-cards" styling
    let bodyShapeCards = document.querySelectorAll(".body-shape-card");
    bodyShapeCards.forEach((element) => {
      element.classList.remove("checked-card");
    });

    // Mouse click and Drag functionality
    const slider = document.querySelector(".container-body-shapes");
    let mouseDown = false;
    let startX, scrollLeft;

    const startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const stopDragging = function (e) {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }

      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;

      changeActiveSliderDot();
    });

    let scrollStartPos = 0;
    const startTouch = function (e) {
      scrollStartPos = this.scrollLeft + e.touches[0].pageX;
      changeActiveSliderDot();
    };

    const startTouchMove = function (e) {
      this.scrollLeft = scrollStartPos - e.touches[0].pageX;
      changeActiveSliderDot();
    };

    // Add the event listeners
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
    slider.addEventListener("touchstart", startTouch, false);
    slider.addEventListener("touchmove", startTouchMove, false);

    // Center the scroll-body-shapes container view on first window loading
    let container = document.querySelector(".container-body-shapes");
    let middle = container.children[Math.floor((container.children.length - 1) / 2)];
    container.scrollLeft = middle.offsetLeft + middle.offsetWidth / 2 - container.offsetWidth / 2;

    // Outline the body shape card if the card was already selected (value exists)
    const bodyShapeButtons = document.querySelectorAll("input[name=" + name + "]");
    if (value !== "") {
      let selectedBodyShapeButton = document.querySelector(
        "input[name=" + name + "][value=" + value + "]"
      );
      selectedBodyShapeButton.parentElement.classList.add("checked-card");
      bodyShapeButtons.forEach((element) => {
        element.checked = false;
      });
      selectedBodyShapeButton.checked = true;

      // Scroll the view to the left border on the selected body-shape-card
      let container = selectedBodyShapeButton.parentElement.parentElement.parentElement;
      container.scrollLeft = selectedBodyShapeButton.offsetLeft;
      changeActiveSliderDot();
      // Check if the middle body-shape-card is selected based on its value and if it is selected center the view on it.
      if (value === "normal" || value === "average") {
        let middle = container.children[Math.floor((container.children.length - 1) / 2)];
        container.scrollLeft =
          middle.offsetLeft + middle.offsetWidth / 2 - container.offsetWidth / 2;
      }
    } else {
      bodyShapeButtons.forEach((element) => {
        element.checked = false;
      });
    }
  });

  function changeActiveSliderDot() {
    const slider = document.querySelector(".container-body-shapes");
    const scrollLeft = slider.scrollLeft; // current position from the left border

    const sliderDots = document.querySelectorAll(".slider-dot");
    sliderDots.forEach((sliderDot) => {
      sliderDot.classList.remove("slider-active-dot");
    });
    if (scrollLeft < 120) {
      sliderDots[0].classList.add("slider-active-dot"); // left dot
    } else if (scrollLeft > 119 && scrollLeft < 400) {
      sliderDots[1].classList.add("slider-active-dot"); // middle dot
    } else {
      sliderDots[2].classList.add("slider-active-dot"); // right dot
    }
  }

  let type1, type2, type3, txtType1, txtType2, txtType3;
  if (name === "chest") {
    type1 = txtType1 = "narrow";
    type2 = txtType2 = "normal";
    type3 = txtType3 = "wide";
    if (lang === "bg") {
      txtType1 = "Тесни";
      txtType2 = "Нормални";
      txtType3 = "Широки";
    }
  } else if (name === "hips") {
    type1 = txtType1 = "straight";
    type2 = txtType2 = "normal";
    type3 = txtType3 = "wide";
    if (lang === "bg") {
      txtType1 = "Тесен";
      txtType2 = "Среден";
      txtType3 = "Широк";
    }
  } else {
    type1 = txtType1 = "flatter";
    type2 = txtType2 = "average";
    type3 = txtType3 = "rounder";
    if (lang === "bg") {
      txtType1 = "Плосък";
      txtType2 = "Среден";
      txtType3 = "Закръглен";
    }
  }

  const imgAltType1 = `${type1} ${gender} ${name}`;
  const imgSrcType1 = `./${gender}_${name}_${type1}.png`;

  const imgAltType2 = `${type2} ${gender} ${name}`;
  const imgSrcType2 = `./${gender}_${name}_${type2}.png`;

  const imgAltType3 = `${type3} ${gender} ${name}`;
  const imgSrcType3 = `./${gender}_${name}_${type3}.png`;

  let txtSecondary;
  switch (lang) {
    case "en":
      txtSecondary = "Please choose the picture that is the most relevant to you.";
      txtBtnPreviousStep = "Previous step";
      break;
    default:
      txtSecondary = "Избери модела с най-близки форми до теб.";
      txtBtnPreviousStep = "Назад";
  }

  return (
    <div className="screen-body-part-shape">
      <div className="top-text top-text-padding">
        <h2 className="mrg-0">{text}</h2>
        <p className="mrg-0">{txtSecondary}</p>
      </div>
      <div className="container-body-shapes">
        <div className="scroll-body-shapes">
          <div className="body-shape-card">
            <input
              type="radio"
              name={name}
              value={type1}
              onChange={(e) => {
                onRadioButtonChange(e);
              }}
            />
            <img alt={imgAltType1} className="img-body-shape-card" src={imgSrcType1} />
            <div className="text-1gilroy-medium-shark-14px">{txtType1}</div>
          </div>
          <div className="body-shape-card">
            <input
              type="radio"
              name={name}
              value={type2}
              onChange={(e) => {
                onRadioButtonChange(e);
              }}
            />
            <img alt={imgAltType2} className="img-body-shape-card" src={imgSrcType2} />
            <div className="text-1gilroy-medium-shark-14px">{txtType2}</div>
          </div>
          <div className="body-shape-card">
            <input
              type="radio"
              name={name}
              value={type3}
              onChange={(e) => {
                onRadioButtonChange(e);
              }}
            />
            <img alt={imgAltType3} className="img-body-shape-card" src={imgSrcType3} />
            <div className="text-1gilroy-medium-shark-14px">{txtType3}</div>
          </div>
        </div>
      </div>
      <div className="slider-dots">
        <span className="slider-dot"></span>
        <span className="slider-dot slider-active-dot"></span>
        <span className="slider-dot"></span>
      </div>
      <p className="error-msg">{errorMsg}</p>
      <div className="container-btn">
        <ButtonNextStep validate={onNextStep} lang={lang} />
        <Link to={prevWindow}>
          <div className="btn-back">&#x2190; {txtBtnPreviousStep}</div>
        </Link>
      </div>
    </div>
  );
};

export default BodyPartShape;
