import React from "react";
import "./ButtonNextStep.css";

const ButtonNextStep = ({ validate, lang }) => {
  let txtButton;
  switch (lang) {
    case "en":
      txtButton = "CONTINUE";
      break;
    default:
      txtButton = "ПОТВЪРДИ И ПРОДЪЛЖИ";
  }

  return (
    <button onClick={validate} className="btn-next">
      {txtButton}
    </button>
  );
};

export default ButtonNextStep;
