import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProductInfo from "./productInfo/ProductInfo";
import "./FitPreference.css";

const FitPreference = ({
  lang,
  defaultValue,
  onChange,
  prevWindow,
  productInfo,
  txtBtnPreviousStep,
  checkPrevData,
}) => {
  useEffect(() => {
    checkPrevData();
  });

  let txtMain, txtSecondary, txtTooltip, txtSlimFit, txtLooseFit, txtInfo, txtButton;
  switch (lang) {
    case "en":
      txtMain = "Fit Preferences";
      txtSecondary = "How do you prefer your to wear this cloth?";
      txtSlimFit = "Slim";
      txtLooseFit = "Loose";
      txtInfo = "Great! At the next screen, you will receive your size recommendation.";
      txtButton = "CONTINUE";
      if (parseInt(defaultValue) === 0) {
        txtTooltip = "Regular";
      } else if (parseInt(defaultValue) === -1) {
        txtTooltip = "Slim";
      } else {
        txtTooltip = "Loose";
      }
      break;
    default:
      txtMain = "Твоят стил";
      txtSecondary = "Как предпочиташ да ти стои дрехата?";
      txtSlimFit = "по теб";
      txtLooseFit = "свободно";
      txtInfo = "Супер! На следващия екран ще получиш твоята препоръка за размер.";
      txtButton = "ПОТВЪРДИ И ПРОДЪЛЖИ";
      if (parseInt(defaultValue) === 0) {
        txtTooltip = "Нормално";
      } else if (parseInt(defaultValue) === -1) {
        txtTooltip = "По теб";
      } else {
        txtTooltip = "Свободно";
      }
  }

  return (
    <div className="screen-fit-preference">
      <div className="top-text">
        <h2 className="mrg-0">{txtMain}</h2>
        <p className="mrg-0">{txtSecondary}</p>
      </div>
      <div className="container-fit-main-screen">
        <ProductInfo productInfo={productInfo} />

        <div className="container-tooltip">
          <span>{txtTooltip}</span>
          <div className="arrow-down"></div>
        </div>

        <div className="container-fit-slider">
          <input
            name="fitPreference"
            type="range"
            min="-1"
            max="1"
            step="1"
            value={defaultValue}
            className="slider-fit-preference"
            onChange={(e) => {
              onChange(e);
            }}
          />
          <div className="container-text-slider">
            <span className="text-slim-fit">{txtSlimFit}</span>
            <span className="text-oversize">{txtLooseFit}</span>
          </div>
        </div>
        <div className="information">
          <img src="./info_icon.png" alt="info icon" />
          <p>{txtInfo}</p>
        </div>
      </div>

      <div>
        <button type="submit" className="btn-next">
          {txtButton}
        </button>
        <Link to={prevWindow}>
          <div className="btn-back">&#x2190; {txtBtnPreviousStep}</div>
        </Link>
      </div>
    </div>
  );
};

export default FitPreference;
