import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SizeRecommendation.css";
import ProductInfo from "../productInfo/ProductInfo";

const SizeRecommendation = () => {
  const { state } = useLocation();
  const { shopData } = state;
  let data = state.data;

  const productInfo = shopData.productData || "";
  const lang = shopData.lang;

  const navigate = useNavigate();

  const editData = () => {
    navigate("/");
    const iframeData = JSON.stringify({
      sendData: true,
    });
    window.parent.postMessage(iframeData, "*");
  };

  useEffect(() => {
    const sizeRecommendationCard = document.querySelector("#radio_size_" + data);
    const parrentElement =
      sizeRecommendationCard.parentElement.parentElement.parentElement.parentElement;
    parrentElement.classList.toggle("checked-card");

    window.onpopstate = () => {
      editData();
    };
  });

  let txtMain, txtSecondary, txtSize, txtInfo, txtButton, txtBtnPreviousStep;
  switch (lang) {
    case "bg":
      txtMain = `Най-подходящият размер за теб е ${data}`;
      txtSecondary = `Вариант за теб е и размера ${data}, ако искаш да ти стои по теб.`;
      txtSize = `Размер ${data}`;
      txtInfo =
        "Тези препоръки са базирани на информацията, която сподели с нас. Натисни бутона по-долу за да добавиш избрания размер от този артикул към своята поръчка.";
      txtButton = `ДОБАВИ РАЗМЕР ${data} КЪМ КОЛИЧКАТА`;
      txtBtnPreviousStep = "Коригирай данните си";
      break;
    default:
      txtMain = `The recommended for you size is ${data}`;
      txtSecondary = ``;
      txtSize = `Size ${data}`;
      txtInfo =
        "These recommendations are based on the information you have shared with us. Click the button below to add the selected size of this item to your order.";
      txtButton = `ADD SIZE ${data} TO SHOPPING CART`;
      txtBtnPreviousStep = "Edit your data";
  }

  // Create a markup around the selected card
  const addClassChecked = (e) => {
    const SizeRecommendations = document.querySelectorAll(".size-recommendation-card");
    SizeRecommendations.forEach((element) => element.classList.remove("checked-card"));

    const parrentElement = e.target.parentElement.parentElement.parentElement.parentElement;
    parrentElement.classList.toggle("checked-card");
  };

  // Show "Recommended" text to the better size (if more than one size)
  let recommendationText = false;
  // if (Object.keys(data).length > 1) {
  //   recommendationText = true;
  // }

  let recommendedSize;
  let willFit = false;

  // Check if the recommended size is currently avaialable
  const isSizeAvailable = productInfo.availableSizes.includes(data);
  // Standard size for Dizi
  const isStandardSize = productInfo.availableSizes.includes("стандартен размер"); // for Dizi
  if (isStandardSize) {
    recommendedSize = "стандартен размер";
    if (data === "S" || data === "M" || data === "L") {
      if (lang === "bg") {
        txtMain =
          "Този продукт се предлага в стандартен размер. Този артикул е подходящ за теб, според въведените данни.";
        txtButton = `ДОБАВИ КЪМ КОЛИЧКАТА`;
        txtSize = `${recommendedSize}`;
      }
      data = "стандартен-размер";
      willFit = true;
    } else {
      if (lang === "bg") {
        txtMain =
          "Този продукт се предлага в един размер. Този артикул не е подходящ за теб, според въведените данни.";
        txtButton = `ДОБАВИ КЪМ КОЛИЧКАТА`;
        txtSize = `${recommendedSize}`;
      }
    }
  } else {
    if (!isSizeAvailable) {
      recommendedSize = data + " (not available)";
      txtSize = `${recommendedSize}`;
      txtButton = `Size ${data} is not available`;
    } else {
      recommendedSize = data;
      willFit = true;
    }
  }

  // Remove XXS for Dizi
  if (shopData.shop === "Dizi") {
    if (data === "XXS") {
      data = "XS";
    }
  }

  // Sends message to the store
  const addItemSizeToCart = (e) => {
    e.preventDefault();

    if (!willFit) {
      return;
    }

    const iframeData = JSON.stringify({
      close: true,
      addToCart: true,
      selectedSize: data,
    });
    window.parent.postMessage(iframeData, "*");
  };

  return (
    <div className="screen-fit-preference">
      <div className="top-text">
        <h2 className="mrg-0">{txtMain}</h2>
        {/* <p className="mrg-0">Вариант за теб е и размера {data}, ако искаш да ти стои по теб.</p> */}
      </div>
      <ProductInfo productInfo={productInfo} />
      {/* {Object.values(data).map((value, index) => { */}
      {/* // console.log(value); */}
      {/* return ( */}
      <label key={data} htmlFor={"radio_size_" + data}>
        <div className="size-recommendation-card">
          <div className="flex-row">
            <div>
              {/* <label className="inline-radio-btn" htmlFor={"radio_size_" + value}> */}
              <div className="inline-radio-btn">
                {txtSize}
                <input
                  id={"radio_size_" + data}
                  type="radio"
                  name="size"
                  className="radio-btn"
                  value={data}
                  onChange={(e) => {
                    addClassChecked(e);
                  }}
                  checked
                />
                <span className="checkmark"></span>
              </div>
              {/* </label> */}
            </div>

            {/* Can be optimized by receiving from server information if this sized is recommended */}
            {recommendationText ? (
              <div className="black-box-text">
                <span>Препоръчано</span>
              </div>
            ) : (
              <span></span>
            )}
            {(recommendationText = false)}
          </div>
          {/* TO BE DONE
           <div>
            <progress id="matchingPercentage" value="75" max="100"></progress>
          </div>
          <span className="text-matching-percents">75% съвпадение</span> */}
        </div>
      </label>
      {/* ); */}
      {/* })} */}
      <div className="information">
        <img src="./info_icon.png" alt="info icon" />
        <p>{txtInfo}</p>
      </div>
      <div>
        {willFit ? (
          <button onClick={addItemSizeToCart} className="btn-next">
            {txtButton}
          </button>
        ) : (
          <button onClick={addItemSizeToCart} className="btn-next inactive">
            {txtButton}
          </button>
        )}
        <div onClick={editData} className="btn-back">
          {txtBtnPreviousStep}
        </div>
      </div>
    </div>
  );
};

export default SizeRecommendation;
