import React, { useState, useEffect } from "react";
import BodyPartShape from "./components/BodyPartShape/BodyPartShape";
import BodyMeasures from "./components/BodyMeasures";
import BraSize from "./components/BraSize/BraSize";
import FitPreference from "./components/FitPreference";
import "./form.css";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const InputField = () => {
  const [formData, setFormData] = useState({
    gender: "female",
    age: "",
    height: "",
    weight: "",
    chest: "",
    hips: "",
    belly: "",
    braBust: "",
    braCup: "",
    fitPreference: "0",
  });
  console.log(formData);

  const [formErrors, setFormErrors] = useState({});
  const [shopData, setShopData] = useState({});

  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const lang = shopData.lang;

  const getMessageData = () => {
    window.addEventListener("message", function (e) {
      // Get the sent data
      if (typeof e.data === "string") {
        const shopData = e.data;
        const parsedShopData = JSON.parse(shopData);
        // Add the parsed data to the state
        setShopData(parsedShopData);
        if (parsedShopData.gender !== "selectable") {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              gender: parsedShopData.gender,
            };
          });
        }
      }
    });
  };

  useEffect(() => {
    getMessageData();

    console.log("sending request");
    // Check if user data exists
    axios
      .post(
        "https://sizeandfit.stailor.io/api/loaduserdata",
        {
          checkUserExists: true,
        },
        { withCredentials: true }
      )
      .then((res) => {
        console.log("res received");
        if (res.data) {
          const userData = res.data;
          for (const data in userData) {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                [data]: userData[data],
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Checks if inital data is set when a person lands on other than first screen. F.ex.: This could happen when the user refresh the page or when he enters the url manually. If weight (data from first screen) is missing, the user is redirected back to the first screen.
  const checkPrevDataIsFull = () => {
    if (pathname !== "/" && !formData.weight) {
      navigate("/");
    }
  };

  let txtChest, txtBelly, txtHip, txtBtnPreviousStep;
  switch (lang) {
    case "bg":
      txtChest = "Форма на рамена и гърди";
      txtBelly = "Форма на корема ти";
      txtHip = "Форма на ханша";
      txtBtnPreviousStep = "Назад";
      break;
    default:
      txtChest = "Shape of your chest";
      txtBelly = "Shape of your belly";
      txtHip = "Shape of your hip";
      txtBtnPreviousStep = "Previous step";
  }

  const validateBodyMeasures = (e) => {
    e.preventDefault();

    const errors = {};
    let isValid = true;

    if (!formData.age) {
      switch (lang) {
        case "bg":
          errors.age = "Моля попълни възрастта си";
          break;
        default:
          errors.age = "Please state your age";
      }
      isValid = false;
    } else if (formData.age < 14 || formData.age > 99) {
      switch (lang) {
        case "bg":
          errors.age = "Трябва да си между 14 и 99 години";
          break;
        default:
          errors.age = "Your age must be between 14 and 99 years";
      }
      isValid = false;
    }

    if (!formData.height) {
      switch (lang) {
        case "bg":
          errors.height = "Моля попълни височината си";
          break;
        default:
          errors.height = "Please state your height";
      }
      isValid = false;
    } else if (formData.height < 140 || formData.height > 220) {
      switch (lang) {
        case "bg":
          errors.height = "Моля въведи стойност между 140 и 220 сантиметра";
          break;
        default:
          errors.height = "Your height must be between 140 and 220 centimeters";
      }
      isValid = false;
    }

    if (!formData.weight) {
      switch (lang) {
        case "bg":
          errors.weight = "Моля въведи теглото си";
          break;
        default:
          errors.weight = "Please state your weight";
      }
      isValid = false;
    } else if (formData.weight < 35 || formData.weight > 200) {
      switch (lang) {
        case "bg":
          errors.weight = "Моля въведи стойност между 35 и 200 килограма";
          break;
        default:
          errors.weight = "Your weight must be between 35 and 200 kilograms";
      }
      isValid = false;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && isValid) {
      navigate("/body-part-shape1");
    }
  };

  const validateBodyShapeChest = (e) => {
    e.preventDefault();

    const errors = {};
    let isValid = true;

    if (!formData.chest) {
      switch (lang) {
        case "bg":
          errors.chest = "Моля избери една от опциите по-горе";
          break;
        default:
          errors.chest = "Please choose one of the options above";
      }
      isValid = false;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && isValid) {
      navigate("/body-part-shape2");
    }
  };

  const validateBodyShapeHips = (e) => {
    e.preventDefault();

    const errors = {};
    let isValid = true;

    if (!formData.hips) {
      switch (lang) {
        case "bg":
          errors.hips = "Моля избери една от опциите по-горе";
          break;
        default:
          errors.hips = "Please choose one of the options above";
      }
      isValid = false;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && isValid) {
      navigate("/body-part-shape2");
    }
  };

  const validateBodyShapeBelly = (e) => {
    e.preventDefault();

    const errors = {};
    let isValid = true;

    if (!formData.belly) {
      switch (lang) {
        case "bg":
          errors.belly = "Моля избери една от опциите по-горе";
          break;
        default:
          errors.belly = "Please choose one of the options above";
      }
      isValid = false;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && isValid) {
      if (formData.gender === "male") {
        navigate("/fit-preference");
      } else {
        navigate("/bra-size");
      }
    }
  };

  const validateBraSize = (e) => {
    e.preventDefault();

    const errors = {};
    let isValid = true;

    if (!formData.braBust) {
      switch (lang) {
        case "bg":
          errors.bust = "Моля избери един от размерите по-горе";
          break;
        default:
          errors.bust = "Please choose one of the sizes above.";
      }
      isValid = false;
    }

    if (!formData.braCup) {
      switch (lang) {
        case "bg":
          errors.cup = "Моля избери един от размерите по-горе";
          break;
        default:
          errors.cup = "Please choose one of the sizes above.";
      }
      isValid = false;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && isValid) {
      navigate("/fit-preference");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data;
    axios
      .post(
        "https://sizeandfit.stailor.io/api",
        {
          customerData: formData,
          shopData: shopData,
        },
        { withCredentials: true }
      )
      .then((res) => {
        data = res.data;

        navigate("/size-recommendation", { state: { data, shopData } });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Routes>
          <Route
            path="/"
            element={
              <BodyMeasures
                lang={shopData.lang}
                gender={formData.gender}
                age={formData.age}
                height={formData.height}
                weight={formData.weight}
                ageInput="age"
                heightInput="height"
                weightInput="weight"
                onChange={handleInputChange}
                onRadioButtonChange={handleInputChange}
                onNextStep={validateBodyMeasures}
                errorAge={formErrors.age}
                errorHeight={formErrors.height}
                errorWeight={formErrors.weight}
                errorMsg={formErrors.dataError}
                productInfo={shopData.productData || ""}
                shopShowGender={shopData.gender || "selectable"}
              />
            }
          />
          {formData.gender === "male" ? (
            <>
              <Route
                path="/body-part-shape1"
                element={
                  <BodyPartShape
                    lang={shopData.lang}
                    gender={formData.gender}
                    text={txtChest}
                    name="chest"
                    type1="narrow"
                    type2="normal"
                    type3="wide"
                    value={formData.chest}
                    onRadioButtonChange={handleInputChange}
                    onNextStep={validateBodyShapeChest}
                    errorMsg={formErrors.chest}
                    prevWindow="/"
                    txtBtnPreviousStep={txtBtnPreviousStep}
                    checkPrevData={checkPrevDataIsFull}
                  />
                }
              />
              <Route
                path="/fit-preference"
                element={
                  <FitPreference
                    lang={shopData.lang}
                    defaultValue={formData.fitPreference}
                    onChange={handleInputChange}
                    prevWindow="/body-part-shape2"
                    nextWindow=""
                    productInfo={shopData.productData || ""}
                    txtBtnPreviousStep={txtBtnPreviousStep}
                    checkPrevData={checkPrevDataIsFull}
                  />
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/body-part-shape1"
                element={
                  <BodyPartShape
                    lang={shopData.lang}
                    gender={formData.gender}
                    text={txtHip}
                    name="hips"
                    type1="straight"
                    type2="normal"
                    type3="wide"
                    value={formData.hips}
                    onRadioButtonChange={handleInputChange}
                    onNextStep={validateBodyShapeHips}
                    errorMsg={formErrors.hips}
                    prevWindow="/"
                    txtBtnPreviousStep={txtBtnPreviousStep}
                    checkPrevData={checkPrevDataIsFull}
                  />
                }
              />
              <Route
                path="/fit-preference"
                element={
                  <FitPreference
                    lang={shopData.lang}
                    defaultValue={formData.fitPreference}
                    onChange={handleInputChange}
                    prevWindow="/bra-size"
                    nextWindow=""
                    productInfo={shopData.productData || ""}
                    txtBtnPreviousStep={txtBtnPreviousStep}
                    checkPrevData={checkPrevDataIsFull}
                  />
                }
              />
            </>
          )}
          <Route
            path="/body-part-shape2"
            element={
              <BodyPartShape
                lang={shopData.lang}
                gender={formData.gender}
                text={txtBelly}
                name="belly"
                type1="flatter"
                type2="average"
                type3="rounder"
                value={formData.belly}
                onRadioButtonChange={handleInputChange}
                onNextStep={validateBodyShapeBelly}
                errorMsg={formErrors.belly}
                prevWindow="/body-part-shape1"
                txtBtnPreviousStep={txtBtnPreviousStep}
                checkPrevData={checkPrevDataIsFull}
              />
            }
          />
          <Route
            path="/bra-size"
            element={
              <BraSize
                lang={shopData.lang}
                braValue={formData.braBust}
                cupValue={formData.braCup}
                onRadioButtonChange={handleInputChange}
                onNextStep={validateBraSize}
                errorBust={formErrors.bust}
                errorCup={formErrors.cup}
                prevWindow="/body-part-shape2"
                txtBtnPreviousStep={txtBtnPreviousStep}
                checkPrevData={checkPrevDataIsFull}
              />
            }
          />
        </Routes>
      </form>
    </>
  );
};

export default InputField;
