import React from "react";

const ProductInfo = ({ productInfo }) => {
  if (Object.keys(productInfo).length > 0) {
    return (
      <div className="product-information">
        <img src={productInfo.img} alt="product" />
        <h4 style={{ margin: 0 }}>{productInfo.name}</h4>
        <p style={{ margin: 0 }}>Barcode: {productInfo.barcode}</p>
      </div>
    );
  } else {
    return <div className="product-information"></div>;
  }
};

export default ProductInfo;
