import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./BraSize.css";
import ButtonNextStep from "../ButtonNextStep";

const BraSize = ({
  lang,
  braValue,
  cupValue,
  onRadioButtonChange,
  onNextStep,
  errorBust,
  errorCup,
  prevWindow,
  txtBtnPreviousStep,
  checkPrevData,
}) => {
  useEffect(() => {
    checkPrevData();
    // Remove any "selected-box" styling
    let braSelectedBoxes = document.querySelectorAll(".selected-box");
    braSelectedBoxes.forEach((element) => {
      element.classList.remove("selected-box");
    });

    // Outline the bust size box if size was already selected (value exists)
    // let bustSize = document.querySelectorAll("input[name='braBust']");
    // console.log(bustSize);
    if (braValue !== "") {
      let selectedBustSize = document.querySelector(
        "input[name='braBust'][value='" + braValue + "']"
      );
      // console.log(selectedBustSize);
      selectedBustSize.parentElement.parentElement.classList.add("selected-box");
    }
    if (cupValue !== "") {
      let selectedCupSize = document.querySelector(
        "input[name='braCup'][value='" + cupValue + "']"
      );
      selectedCupSize.parentElement.parentElement.classList.add("selected-box");
    }
  });

  let txtMain, txtSecondary, txtBust, txtCup;
  switch (lang) {
    case "en":
      txtMain = "What is your bra size?";
      txtSecondary = "Choose the bust and cup size you normally wear.";
      txtBust = "Bust";
      txtCup = "Cup";
      break;
    default:
      txtMain = "Кой номер сутиен носиш?";
      txtSecondary = "Избери номера за бюст и чашка, които носиш обикновено.";
      txtBust = "Бюст";
      txtCup = "Чашка";
  }

  return (
    <div className="screen-fit-preference">
      <div className="top-text">
        <h2 className="mrg-0">{txtMain}</h2>
        <p className="mrg-0">{txtSecondary}</p>
      </div>
      <div className="container-fit-main-screen">
        <table className="tbls-bust-cup">
          <thead>
            <tr>
              <th colSpan={4}>{txtBust}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="selectable">
                <label htmlFor="bust_60">
                  <input
                    type="radio"
                    name="braBust"
                    value="60"
                    id="bust_60"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  60
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_65">
                  <input
                    type="radio"
                    name="braBust"
                    value="65"
                    id="bust_65"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  65
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_70">
                  <input
                    type="radio"
                    name="braBust"
                    value="70"
                    id="bust_70"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  70
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_75">
                  <input
                    type="radio"
                    name="braBust"
                    value="75"
                    id="bust_75"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  75
                </label>
              </td>
            </tr>
            <tr>
              <td className="selectable">
                <label htmlFor="bust_80">
                  <input
                    type="radio"
                    name="braBust"
                    value="80"
                    id="bust_80"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  80
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_85">
                  <input
                    type="radio"
                    name="braBust"
                    value="85"
                    id="bust_85"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  85
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_90">
                  <input
                    type="radio"
                    name="braBust"
                    value="90"
                    id="bust_90"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  90
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_95">
                  <input
                    type="radio"
                    name="braBust"
                    value="95"
                    id="bust_95"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  95
                </label>
              </td>
            </tr>
            <tr>
              <td className="selectable">
                <label htmlFor="bust_100">
                  <input
                    type="radio"
                    name="braBust"
                    value="100"
                    id="bust_100"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  100
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_105">
                  <input
                    type="radio"
                    name="braBust"
                    value="105"
                    id="bust_105"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  105
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_110">
                  <input
                    type="radio"
                    name="braBust"
                    value="110"
                    id="bust_110"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  110
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_115">
                  <input
                    type="radio"
                    name="braBust"
                    value="115"
                    id="bust_115"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  115
                </label>
              </td>
            </tr>
            <tr>
              <td className="selectable">
                <label htmlFor="bust_120">
                  <input
                    type="radio"
                    name="braBust"
                    value="120"
                    id="bust_120"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  120
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="bust_125">
                  <input
                    type="radio"
                    name="braBust"
                    value="125"
                    id="bust_125"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  125
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="error-msg">{errorBust}</p>

        <table className="tbls-bust-cup">
          <thead>
            <tr>
              <th colSpan={4}>{txtCup}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="selectable">
                <label htmlFor="cup_AA">
                  <input
                    type="radio"
                    name="braCup"
                    value="AA"
                    id="cup_AA"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  AA
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_A">
                  <input
                    type="radio"
                    name="braCup"
                    value="A"
                    id="cup_A"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  A
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_B">
                  <input
                    type="radio"
                    name="braCup"
                    value="B"
                    id="cup_B"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  B
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_C">
                  <input
                    type="radio"
                    name="braCup"
                    value="C"
                    id="cup_C"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  C
                </label>
              </td>
            </tr>
            <tr>
              <td className="selectable">
                <label htmlFor="cup_D">
                  <input
                    type="radio"
                    name="braCup"
                    value="D"
                    id="cup_D"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  D
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_E">
                  <input
                    type="radio"
                    name="braCup"
                    value="E"
                    id="cup_E"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  E
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_F">
                  <input
                    type="radio"
                    name="braCup"
                    value="F"
                    id="cup_F"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  F
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_G">
                  <input
                    type="radio"
                    name="braCup"
                    value="G"
                    id="cup_G"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  G
                </label>
              </td>
            </tr>
            <tr>
              <td className="selectable">
                <label htmlFor="cup_H">
                  <input
                    type="radio"
                    name="braCup"
                    value="H"
                    id="cup_H"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  H
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_I">
                  <input
                    type="radio"
                    name="braCup"
                    value="I"
                    id="cup_I"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  I
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="J">
                  <input
                    type="radio"
                    name="braCup"
                    value="J"
                    id="J"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  J
                </label>
              </td>
              <td className="selectable">
                <label htmlFor="cup_K">
                  <input
                    type="radio"
                    name="braCup"
                    value="K"
                    id="cup_K"
                    onChange={(e) => {
                      onRadioButtonChange(e);
                    }}
                  />
                  K
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="error-msg">{errorCup}</p>
      </div>

      <div>
        <ButtonNextStep validate={onNextStep} lang={lang} />
        <Link to={prevWindow}>
          <div className="btn-back">&#x2190; {txtBtnPreviousStep}</div>
        </Link>
      </div>
    </div>
  );
};

export default BraSize;
