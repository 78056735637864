import React from "react";
import InputField from "./InputField";
import SizeRecommendation from "./components/SizeRecommendation/SizeRecommendation";
import Footer from "./components/footer/Footer";
import "./app.css";
import { Route, Routes } from "react-router-dom";

const App = () => {
  const closeIframe = () => {
    const iframeData = JSON.stringify({
      close: true,
    });
    window.parent.postMessage(iframeData, "*");
  };

  return (
    // <React.StrictMode>
    <>
      <div className="close-app" onClick={closeIframe}></div>
      <div className="container-app">
        <button className="btn-close" onClick={closeIframe}>
          X
        </button>
        <Routes>
          <Route path="*" element={<InputField />} />
          {/* <InputField /> */}
          <Route path="/size-recommendation" element={<SizeRecommendation />} />
        </Routes>
        <Footer />
      </div>
      {/* </div> */}
    </>
    // </React.StrictMode>
  );
};

export default App;
